import React, { memo, useMemo } from 'react';
import { Row, useTable } from 'react-table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { IPlannedContribution } from 'types';

import { LoadingIndicator, Table, TableBody, TableCell, TableRow } from 'atoms';

import { Colors } from 'services/ColorService';
import { getFontFamily } from 'services/FontService';

type Accessors =
  | 'period'
  | 'planByn'
  | 'factByn'
  | 'planRub'
  | 'factRub'
  | 'planUsd'
  | 'factUsd'
  | 'planEur'
  | 'factEur';

interface IColumn {
  accessor: Accessors;
  alignContent?: 'left' | 'right' | 'center';
  columnWidth?: number;
  Header?: string | React.ReactElement;
  flexible?: boolean;
  marginLeft?: number;
}

const getColumnsSource = (t: TFunction): IColumn[] => [
  {
    Header: `${t('table.finance.month')}`,
    accessor: 'period',
    flexible: true,
  },
  {
    Header: `${t('table.finance.planCurrencyFirst')}`,
    accessor: 'planByn',
    columnWidth: 88,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.factCurrencyFirst')}`,
    accessor: 'factByn',
    columnWidth: 88,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.planCurrencySecond')}`,
    accessor: 'planRub',
    columnWidth: 104,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.factCurrencySecond')}`,
    accessor: 'factRub',
    columnWidth: 104,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.planCurrencyThird')}`,
    accessor: 'planUsd',
    columnWidth: 80,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.factCurrencyThird')}`,
    accessor: 'factUsd',
    columnWidth: 80,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.planCurrencyFourth')}`,
    accessor: 'planEur',
    columnWidth: 80,
    marginLeft: 48,
  },
  {
    Header: `${t('table.finance.factCurrencyFourth')}`,
    accessor: 'factEur',
    columnWidth: 80,
    marginLeft: 48,
  },
];

type IRow = {
  [key in Accessors]: React.ReactNode;
};

export interface IFinanceMonthTableComponentProps {
  weeks?: IPlannedContribution[];
  loading: boolean;
  className?: string;
}

export const FinanceMonthTableComponent = memo(
  ({ weeks, loading, className }: IFinanceMonthTableComponentProps) => {
    const { t } = useTranslation();
    const columns: IColumn[] = useMemo(() => getColumnsSource(t), [t]);
    const rowsSource: IRow[] = useMemo(
      () =>
        weeks?.map((item) => ({
          ...item,
        })) || [],
      [weeks],
    );

    const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
      columns,
      data: rowsSource,
    });

    return (
      <Wrapper className={className}>
        <Table {...getTableProps()} flexible rowShadowOnHover>
          <TableBody {...getTableBodyProps()}>
            {!rows.length && !loading && (
              <TableRow key="noData">
                <TableCell type="cell" width="100%" align="left">
                  {t('table.finance.noDataMonth')}
                </TableCell>
              </TableRow>
            )}

            {!rows.length && loading && (
              <LoadingRow type="row" key="loadingSubRow">
                <LoadingCell>
                  <LoadingCellIndicator />
                  <LoadingCellText>
                    {t('table.finance.loadingData')}
                  </LoadingCellText>
                </LoadingCell>
              </LoadingRow>
            )}

            {Boolean(rows.length) &&
              rows.map((row: Row<IRow>) => {
                prepareRow(row);
                const { key: rowKey, ...restRowProps } = row.getRowProps();

                return (
                  <TableRowStyled key={rowKey} type="row" {...restRowProps}>
                    {row.cells.map((cell: any) => {
                      const width = cell.column.columnWidth
                        ? `${cell.column.columnWidth}px`
                        : 'auto';
                      const marginLeft = cell.column.marginLeft
                        ? `${cell.column.marginLeft}px`
                        : 'auto';

                      const {
                        key: cellKey,
                        ...restCellProps
                      } = cell.getCellProps({
                        flexible: cell.column.flexible || false,
                        width,
                        margin: {
                          left: marginLeft,
                        },
                      });

                      return (
                        <TableCell key={cellKey} {...restCellProps}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}

                    <EmptyCell />
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
`;

const LoadingRow = styled(TableRow)`
  height: 168px;
  background-color: ${Colors.LightBlue};
  pointer-events: none;

  &:after {
    display: none;
  }
`;

const TableRowStyled = styled(TableRow)`
  background-color: ${Colors.LightBlue};

  &:hover {
    z-index: 1;
  }
`;

const LoadingCell = styled.td`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LoadingCellIndicator = styled(LoadingIndicator)`
  height: 24px;
  width: 24px;
`;

const LoadingCellText = styled.span`
  margin-left: 16px;
  font-family: ${getFontFamily('regular')};
  color: ${Colors.Gray};
`;

const EmptyCell = styled.td`
  width: 32px;
  margin-left: 32px;
`;
