import { gql } from '@apollo/client';

export const PlannedYearContributions = gql`
  query PlannedYearContributions($year: Int!) {
    financePlan(year: $year) {
      month
      USD
      EUR
      BYN
      RUB
    }
    financeBudget(year: $year) {
      month
      USD
      EUR
      BYN
      RUB
    }
  }
`;

export const PlannedMonthContributions = gql`
  query PlannedMonthContributions($year: Int!, $month: Int!) {
    financeMonthBudget(year: $year, month: $month) {
      plan {
        week
        USD
        EUR
        BYN
        RUB
      }

      budget {
        week
        USD
        EUR
        BYN
        RUB
      }
    }
  }
`;

export const FinanceYears = gql`
  query FinanceYears {
    financeYears
  }
`;
