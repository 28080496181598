import { PlannedMonthContributionsQuery } from 'core';
import { IPlannedContribution } from 'types';
import i18n from '../../../../i18n';

interface IPlanContributions
  extends Pick<
    IPlannedContribution,
    'period' | 'planByn' | 'planRub' | 'planUsd' | 'planEur'
  > {}

interface IBudgetContributions
  extends Pick<
    IPlannedContribution,
    'period' | 'factByn' | 'factRub' | 'factUsd' | 'factEur'
  > {}

type JoinContributionsType = {
  plan: IPlanContributions[];
  budget: IBudgetContributions[];
};

const joinContributions = (
  args: JoinContributionsType,
): IPlannedContribution[] => {
  const { plan, budget } = args;

  if (plan.length > budget.length) {
    const result: IPlannedContribution[] =
      plan.map((week, index) => ({
        ...week,
        ...(budget[index] || {
          factByn: 0,
          factRub: 0,
          factUsd: 0,
          factEur: 0,
        }),
        period: `${i18n.t('common.week')} ${index + 1}`,
      })) || [];

    return result;
  }

  const result: IPlannedContribution[] =
    budget.map((week, index) => ({
      ...week,
      ...(plan[index] || {
        planByn: 0,
        planRub: 0,
        planUsd: 0,
        planEur: 0,
      }),
      period: `${i18n.t('common.week')} ${index + 1}`,
    })) || [];

  return result;
};

export const transformPlannedMonthContributions = (
  data: PlannedMonthContributionsQuery,
): IPlannedContribution[] => {
  const budget: IBudgetContributions[] =
    data.financeMonthBudget?.budget?.map((week) => ({
      period: `${i18n.t('common.week')} ${week?.week || ''}`,
      factByn: week?.BYN || 0,
      factRub: week?.RUB || 0,
      factUsd: week?.USD || 0,
      factEur: week?.EUR || 0,
    })) || [];

  const plan: IPlanContributions[] =
    data.financeMonthBudget?.plan?.map((week) => ({
      period: `${i18n.t('common.week')} ${week?.week || ''}`,
      planByn: week?.BYN || 0,
      planRub: week?.RUB || 0,
      planUsd: week?.USD || 0,
      planEur: week?.EUR || 0,
    })) || [];

  const joined = joinContributions({ plan: plan || [], budget: budget || [] });

  return joined;
};
